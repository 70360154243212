import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import { PrimaryNav, MobileNav } from "src/components/navigation"
import { useContentfulSection, useIframeBreak, useMeta } from "utils"

const Success = ({ data: { success } }) => {
  const {
    fax_success_content: { title, subtitle, media },
  } = useContentfulSection(success.sections)

  const meta = useMeta(success)

  useIframeBreak()

  return (
    <>
      <MobileNav />
      <PrimaryNav />
      <Wrap>
        <Layout hideFooter>
          <Seo {...meta} />

          <VSection
            titleAs="h1"
            align="left"
            title={title}
            subtitle={subtitle}
            media={media}
          />
        </Layout>
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  --section-align: flex-start;

  background: var(--lightBG);
  --section-background-color: var(--lightBG);

  --tg-title-color: var(--card-shade_1);
  --tg-subtitle-color: var(--card-shade_1);

  .subtitle {
    white-space: break-spaces;
  }

  a {
    color: var(--link_light);
  }

  .v_mediaContainer {
    --v-section-media-top: var(--sp-16);
    --v-section-mobile-media-top: var(--sp-16);
  }
`

export const query = graphql`
  {
    success: contentfulPage(pageId: { eq: "fax_success" }) {
      ...Page
    }
  }
`

export default Success
